@import '../setup/setup';

.btn {
  height: 52px;
  display: flex;
  align-items: center;
  padding: 0 26px;
  border-radius: 26px;
  background: $color-blue;
  color: $color-white;

  svg {
    display: block;
    width: 20px;
    margin: 0 26px 0 0;
  }

  span {
    flex: 1;
  }
}

.item {
  position: relative;
  display: block;
  padding: 20px 40px;
  cursor: pointer;
  color: $color-dark;

  svg {
    display: block;
    width: 35px;
    height: 35px;
    padding: 7px;
  }
}

.active {
  color: $color-blue;
}

.notification {
  position: absolute;
  top: 13px;
  left: 41px;
  font-size: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $color-red;
  transition: all 0.2s ease;
  pointer-events: none;
}

.list {
  li {
    display: block;

    .tooltip {
      background: linear-gradient(
        122.18deg,
        rgb(0, 65, 196) -99.94%,
        rgb(0, 70, 199) 7.25%,
        rgb(35, 214, 247) 104.71%
      );
      color: white;
      padding: 15px;
    }

    a,
    button {
      display: flex;
      align-items: center;
      font-size: 22px;
      font-weight: 500;
      padding: 10px 20px;
      margin-left: -15px;
      margin-right: -15px;
      transition: all 0.2s ease;

      svg {
        width: 35px;
        margin-right: 20px;

        rect[stroke='#fff'] {
          stroke: $color-blue;
        }

        path {
          transition: fill 0.2s ease;
        }
      }
    }
  }
}

@include mq(desktop-small) {
  .item {
    position: relative;
    display: block;
    margin: 17px 25px;
  }
}
