.carousel-root {
    outline: none;
}


/*.carousel * {*/
/*    -webkit-box-sizing: border-box;*/
/*    -moz-box-sizing: border-box;*/
/*    box-sizing: border-box*/
/*}*/

.carousel .carousel {
    position: relative;
}


.carousel.carousel-slider {
    position: relative;
    margin: 0;
    overflow: hidden
}


.carousel .slider-wrapper {
    overflow: hidden;
    margin: auto;
    width: 100%;
    transition: height .15s ease-in
}


.carousel .slider-wrapper.axis-horizontal .slider {
    display: flex;
}


.carousel .slider-wrapper.axis-horizontal .slider .slide {
    flex-direction: column;
}


.carousel .slider-wrapper.axis-vertical {
    display: flex;
}


.carousel .slider-wrapper.axis-vertical .slider {
    flex-direction: column;
}


.carousel .slider {
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none;
    width: 100%
}


.carousel .slider.animated {
    transition: all .35s ease-in-out
}


.carousel .slide {
    min-width: 100%;
    margin: 0;
    position: relative;
    text-align: center;
}
