@import 'setup/setup';
@import 'helpers/reset';
@import 'helpers/fonts';
@import 'helpers/borders';
@import 'helpers/spacings';
@import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
@import './vendor/_react-responsive-carousel.css';
@import './vendor/_landbot.css';

// New font sizes
:root {
  --fs-h1: 19px;
  --fs-h2: 19px;
  --fs-h3: 18px;
  --fs-perex: 14px;
  --fs-body: 14px;
  --fs-small: 12px;
  --fs-caption: 12px;
  --fs-special: 14px;
  --fs-quote: 19px;
  --fs-quote-author: 14px;

  --space-tiny: 5px;
  --space-xxxs: 10px;
  --space-xxs: 15px;
  --space-xs: 20px;
  --space-s: 27px;
  --space-m: 30px;
  --space-l: 40px;
  --space-xl: 50px;
  --space-xxl: 60px;
  --space-xxxl: 90px;
  --space-huge: 110px;

  // Padding for card
  --padding-card: 20px 50px 20px 30px;

  @media (min-width: 768px) {
    --fs-h1: 45px;
    --fs-h2: 23px;
    --fs-perex: 17px;
    --fs-body: 16px;
    --fs-small: 13px;
    --fs-caption: 14px;
    --fs-special: 16px;
    --fs-quote: 24px;
    --fs-quote-author: 15px;

    --padding-card: 30px 50px 30px 30px;
  }

  @media (min-width: 1024px) {
    --fs-h1: 45px;
    --fs-h2: 23px;
    --fs-h3: 18px;
    --fs-perex: 19px;
    --fs-body: 16px;
    --fs-small: 14px;
    --fs-caption: 16px;
    --fs-special: 19px;
    --fs-quote: 35px;
    --fs-quote-author: 16px;
  }
}

body {
  font-family: $font-sans;
  font-size: $fs-base;
  background: $color-white;
  width: 100%;
  overflow-x: hidden;
  font-weight: 500;
}

#__next {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  nav {
  }

  main {
    padding-top: 80px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  footer {
  }
}

.app {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.container {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  //padding: 0 34px;
  padding: 0 20px;
}

.row {
  display: flex;
}

.rowVc {
  align-items: center;
}

.rowSb {
  justify-content: space-between;
}

button {
  background: none;
  font-family: $font-sans;
  font-size: $fs-base;
  border: 0;
  cursor: pointer;
}

button,
label[for] {
  cursor: pointer;
}

label {
  display: block;
}

.button {
  display: flex;
  text-transform: uppercase;
  font-size: $fs-small;
  font-weight: 700;
  padding-right: 25px;
  padding-left: 25px;
  align-items: center;
  justify-content: center;
  height: 46px;
  transition: background 0.2s ease;
  border-radius: 32px;
}

.button__primary {
  background: $color-blue;
  color: $color-white;

  &:not(.buttonDisabled) {
    @include hover() {
      background: darken($color-blue, 10);
    }
  }

  &--publish {
    background: $color-blue;
    color: $color-white;
    margin-left: auto;

    &:not(.buttonDisabled) {
      @include hover() {
        background: darken($color-blue, 10);
      }
    }
  }
}

.button__outline {
  background: $color-white;
  color: $color-black;
  border: 1px solid $color-black;

  &:not(.buttonDisabled) {
    @include hover() {
      color: $color-white;
      background: darken($color-black, 10);
    }
  }
}

.button__form-previous-step {
  display: none;
  justify-content: flex-start;
  margin-right: auto !important;
  padding: 0 !important;
  background: transparent;
  font-size: 16px;
  text-transform: initial;

  .button__arrow-left {
    transition: background-color 0.2s ease, border-color 0.2s ease;
    will-change: background-color, border-color;

    svg path {
      transition: fill 0.2s ease;
      will-change: fill;
    }
  }

  @include hover() {
    text-decoration: none;

    .button__arrow-left {
      background-color: $color-black;

      svg path {
        fill: $color-white;
      }
    }
  }
}

.button__form-next-step {
  display: flex;
  justify-content: flex-end;
  margin-left: auto !important;
  background: $color-blue;
  color: $color-white;
  border: 1px solid $color-blue;

  &--0 {
    display: flex;
    justify-content: flex-end;
    background: $color-white;
    color: $color-black;
    border: 1px solid $color-black;

    .button__arrow-right {
      display: none;
      transition: background-color 0.2s ease;
      will-change: background-color;

      svg path {
        transition: fill 0.2s ease;
        will-change: fill;
      }
    }

    @include hover() {
      background: $color-black;
      color: $color-white;
      text-decoration: none;

      .button__arrow-right {
        background-color: $color-blue;

        svg path {
          fill: $color-white;
        }
      }
    }
  }

  .button__arrow-right {
    display: none;
    transition: background-color 0.2s ease;
    will-change: background-color;

    svg path {
      transition: fill 0.2s ease;
      will-change: fill;
    }
  }

  @include hover() {
    background: darken($color-blue, 10);
    text-decoration: none;

    .button__arrow-right {
      background-color: $color-blue;

      svg path {
        fill: $color-white;
      }
    }
  }
}

.button__secondary {
  background: $color-black;
  color: $color-white;

  &--outline {
    background: $color-white;
    color: $color-black;
    border: 1px solid $color-black;

    &:hover {
      background: $color-black;
      color: $color-white;
    }
  }
}

.button__danger {
  background: $color-red;
  color: $color-white;
}

.button__success {
  background: $color-green;
  color: $color-white;
}

.button__alert {
  background: #f88d00;
  color: $color-white;
}

.button__arrow-left {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  border: 1px solid #f1f1f3;
}

.button__arrow-right {
  margin-left: 15px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  border: 1px solid $color-blue;
}

.button__plus-marker {
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  flex-shrink: 0;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $color-white;
    transition: all 0.2s ease;
  }

  &:before {
    width: 2px;
    height: 14px;
  }

  &:after {
    width: 14px;
    height: 2px;
  }
}

.buttonLoading {
  //opacity: .5;
  cursor: not-allowed;
}

.buttonDisabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.sticky {
  position: sticky;
  z-index: 1;
}

.stickyTop {
  top: 0;
}

.stickyBottom {
  bottom: 0;
}

.page {
  padding-top: 45px;
  padding-bottom: 45px;
}

.react-toast-notifications__toast__content {
  min-height: auto !important;
}

.loadingText {
  position: relative;
  font-family: $font-loading !important;
  //color: $color-gray !important;
  color: #e4e4e4;
  pointer-events: none;
  user-select: none;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: min(10%, 20px);
    height: 100%;
    background: $color-white;
    animation: slide 2s ease-in-out infinite;
    transform: skew(-25deg);
    filter: blur(10px);
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 4;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease;

  &.active {
    opacity: 1;
    pointer-events: all;
  }
}

.rich-editor {
  .demo-editor {
    padding: 0 10px;
  }

  .rdw-editor-main {
    blockquote {
      border-left-color: $color-gray-darker;
    }
  }

  .notranslate.public-DraftEditor-content {
    padding-bottom: 30px;
    line-height: 1.6;
  }
}

.rich-editor-toolbar {
  .rdw-option-wrapper {
    &:not(.rdw-colorpicker-option) {
      height: 100%;
    }

    @include hover {
      box-shadow: none;
      background-color: $color-gray;
    }

    &.rdw-option-active {
      box-shadow: none;
      background-color: $color-gray-darker;
    }
  }

  .rdw-dropdown-wrapper {
    .rdw-dropdown-carettoopen,
    .rdw-dropdown-carettoclose {
      right: 6px;
    }

    @include hover {
      box-shadow: none;
      background-color: $color-gray;
    }
  }

  .rdw-dropdown-optionwrapper {
    @include hover {
      box-shadow: none;
    }
  }

  .rdw-dropdown-carettoopen,
  .rdw-dropdown-carettoclose {
    top: 12px;
  }

  .rdw-fontsize-dropdown {
    min-width: 47px;

    .rdw-dropdown-carettoopen,
    .rdw-dropdown-carettoclose {
      right: 6px;
    }
  }
}

.overflowHidden {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.fileExtLabel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $color-dark;
  text-transform: uppercase;
  color: $color-white;
  font-size: 11px;
  font-weight: 600;
  padding: 3px;
  text-align: center;
  min-width: 42px;
}

textarea,
input {
  font-family: $font-sans;
}

@keyframes slide {
  0% {
    left: 0;
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  80% {
    opacity: 1;
  }

  100% {
    left: 100px;
    opacity: 0;
  }
}

@include mq(tablet) {
  body {
    background: $color-gray;
  }
  .rich-editor {
    .demo-editor {
      padding: 0 30px;
    }
  }

  .button__form-previous-step {
    display: flex;
  }

  .button__form-next-step {
    background-color: transparent;
    color: $color-black;
    padding: 0 !important;
    border: none;
    text-decoration: underline;

    .button__arrow-right {
      display: flex;
    }

    @include hover() {
      background: transparent;
      color: $color-black;
    }

    &--0 {
      position: absolute;
      right: 36px;
      bottom: 26px;
      text-decoration: underline;
      border: none;

      .button__arrow-right {
        display: flex;
      }

      @include hover() {
        background: transparent;
        color: $color-black;
      }
    }
  }
}

@include mq(desktop-small) {
  .container {
    padding: 0 40px;
  }

  .page {
    border: 1px solid #e4e4e4;
  }
}

@include mq(desktop-wide) {
  .container {
    padding: 0 50px;
  }

  .page {
    //padding-top: 80px;
    //padding-bottom: 80px;
  }
}

.slick-track * {
  outline: none;
}

.textLeft {
  text-align: left;
}

.textCenter {
  text-align: center;
}

.textRight {
  text-align: right;
}

// LAST PASS FIX
[id^='__lpform'] {
  display: none;
}

// CKEDITOR FIXES
.ck.ck-toolbar.ck-toolbar_grouping > .ck-toolbar__items {
  flex-wrap: wrap !important;
}

.ck-dropdown__button {
  border: 0 !important;
}

.ck-editor.ck-rounded-corners {
  border-radius: 0 !important;
}

.ck-content blockquote {
}
