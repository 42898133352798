.border-gray {
  border: 1px solid #e4e4e4;
}

.border-gray-bottom {
  border-bottom: 1px solid #e4e4e4;
}

.border-gray-top {
  border-top: 1px solid #e4e4e4;
}

.border-accent {
  position: relative;
  border: 1px solid #232735;
  border-bottom: 0;

  &:after {
    content: '';
    position: absolute;
    bottom: -3px;
    left: -1px;
    width: calc(100% + 2px);
    height: 3px;
    background-color: $color-blue;
  }
}
