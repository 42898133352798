@import '../setup/setup';

.inputWrapper {
  position: relative;
  margin-bottom: 1.5rem;
  cursor: text;

  &:not(.hasLabelAbove) {
    label {
      position: absolute;
      top: 21px;
      left: 10px;
      transition: all 0.2s ease;
    }
  }

  & + & {
    margin-top: var(--space-xs);
  }

  &.isHidden {
    display: none;
  }

  input[disabled] {
    color: $color-gray-darker;
    //border-color: $color-black;
    border-color: #e4e4e4;
    cursor: not-allowed;
  }
}

.inputWrapperdark {
  .input {
    color: $color-black;
    border-bottom-width: 1px;
    border-color: #e4e4e4;
  }
}

.inputWrapperblue {
  .input {
    color: $color-blue;
    border-bottom-width: 1px;
    border-color: #e4e4e4;
  }
}

.inputWrapperError {
  label {
    color: $color-error;
  }

  .input {
    color: $color-error;
    border-bottom-color: $color-error;
    border-bottom-width: 1px;
  }
}

.error {
  color: $color-error;
  font-size: var(--fs-small);
  padding-top: 8px;
  padding-left: 10px;
}

.password {
  position: absolute;
  top: 18px;
  right: 10px;
  width: 23px;
  height: 23px;
  background: #c1c1c1;
  display: flex;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.2s ease;

  @include hover {
    background: darken(#c1c1c1, 5);
  }

  svg {
    display: block;
    width: 13px;
    margin: auto;
  }
}

.input {
  width: 100%;
  margin: 0;
  border-bottom: 1px solid #e4e4e4;
  background: transparent;
  font-size: var(--fs-body);
  font-weight: 500;
  color: $color-black;
  padding: var(--space-xs) var(--space-xxxs);

  &.hasLabelAbove {
    &::placeholder {
      opacity: 1;
    }
  }

  &:not(.hasLabelAbove) {
    &.inputActive + label {
      top: -3px;
      font-size: $fs-small;
      color: #aaa;
    }
  }

  &::-webkit-input-placeholder {
    color: #aaa;
    opacity: 1;
  }

  &::-moz-placeholder {
    color: #aaa;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: #aaa;
    opacity: 1;
  }

  &:-moz-placeholder {
    color: #aaa;
    opacity: 1;
  }

  &::placeholder {
    color: #aaa;
    opacity: 1;
  }
}

input.input {
  padding: var(--space-xs) var(--space-xxxs);
}

textarea.input {
  padding: var(--space-xs);
}

.inputQuote {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20px;
  right: 18px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: $color-white;

  svg {
    width: 16px;
  }
}

.textBox {
  input {
    background: #f2f2f2;
    height: 246px;
  }
}

textarea.quoteArea {
  line-height: 1.2;
  padding-top: 26px;
  padding-right: 65px;
  padding-left: 25px;
}

@include mq(tablet) {
  .inputWrapper {
    label {
      font-size: var(--fs-body);
    }
  }

  .input {
    &.inputActive + label {
      top: -5px;
    }
  }

  .inputQuote {
    top: 30px;
    right: auto;
    left: 18px;
  }

  textarea.quoteArea {
    padding-top: 36px;
    padding-right: auto;
    padding-left: 65px;
  }
}

.labelAbove {
  position: static;
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #333;
}

.inputContainer {
  position: relative;
}
