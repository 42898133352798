//.space-xxxs {--space-xxxs: 10px;}
//.space-xxs {--space-xxs: 15px;}
//.space-xs {--space-xs: 20px;}
//.space-s {--space-s: 25px;}
//.space-m {--space-m: 30px;}
//.space-l {--space-l: 40px;}
//.space-xl {--space-xl: 50px;}
//.space-xxl {--space-xxl: 60px;}
//.space-xxxl {--space-xxxl: 90px;}
//.space-huge {--space-huge: 110px;}

$_sizes: 'xxxs' var(--space-xxxs), 'xxs' var(--space-xxs), 'xs' var(--space-xs),
  's' var(--space-s), 'm' var(--space-m), 'l' var(--space-l),
  'xl' var(--space-xl), 'xxl' var(--space-xxl), 'xxxl' var(--space-xxxl),
  'huge' var(--space-huge), 'card' var(--padding-card);

@each $name, $size in $_sizes {
  .mb-#{$name} {
    margin-bottom: $size;
  }

  .mt-#{$name} {
    margin-top: $size;
  }

  .ml-#{$name} {
    margin-left: $size;
  }

  .mr-#{$name} {
    margin-right: $size;
  }

  .p-#{$name} {
    padding: $size;
  }

  .pb-#{$name} {
    padding-bottom: $size;
  }
}
