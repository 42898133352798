@import '../../setup/setup';

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  display: flex;
  background: rgba(0, 0, 0, 0.8);
}

.popUp {
  width: 100%;
  margin: auto;
  padding: 40px;
  background-color: $color-white;
  text-align: left;

  p {
    margin-bottom: 30px;
    line-height: 20px;
  }
}

.title {
  font-size: $fs-mob-h4;
  margin-bottom: 30px;
  font-family: $font-sans;
  line-height: 28px;
  font-weight: 500;
}

.buttonWrapper {
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.confirmButton,
.cancelButton {
  flex: 1;
}

.cancelButton {
  background: #aaa;
  margin-right: 5px;
}

.expert {
  position: relative;
  color: $color-black;
  display: inline-flex;
  justify-content: left;
  font-size: 10px;
  font-weight: 600;
  margin: 0 10px 40px 0;
  flex-grow: 1;
  height: 43px;
}

.titlesWrapper {
  display: flex;
  flex-direction: column;
  margin: auto 0;
  padding-left: 20px;
  text-align: left;
}

.image {
  width: 43px;
  height: 100%;

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}

.avatar {
  width: 43px;
  height: 43px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
}

.name {
  font-size: $fs-base;
  font-weight: 600;
}

.position {
  font-size: $fs-label;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0;
}

@include mq(tablet) {
  .popUp {
    width: 647px;
  }

  .title {
    font-size: $fs-h4;
    line-height: 38.4px;
  }
}
