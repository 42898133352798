@mixin font-face(
  $font-family,
  $url,
  $font-weight: normal,
  $font-style: normal
) {
  @font-face {
    font-family: '#{$font-family}';
    src: url('/fonts/#{$font-family}/#{$url}.eot');
    src: url('/fonts/#{$font-family}/#{$url}.eot?#iefix')
        format('embedded-opentype'),
      url('/fonts/#{$font-family}/#{$url}.woff') format('woff'),
      url('/fonts/#{$font-family}/#{$url}.ttf') format('truetype'),
      url('/fonts/#{$font-family}/#{$url}.svg') format('svg');
    font-weight: $font-weight;
    font-style: $font-style;
  }
}
