@import '../setup/setup';

.footer {
  position: relative;
  background: $color-gray;
  color: $color-black;
  padding-top: 64px;
  padding-bottom: 64px;
  @include mq(tablet, max) {
    padding-top: 46px;
    padding-bottom: 46px;
  }
}

.footerContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  //padding: 0 20px;
  flex-direction: column;

  > div {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }
}

.logo {
  text-align: left;
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;

  svg {
    width: 100%;
    height: auto;
    max-width: 220px;
  }

  span {
    font-family: $font-sans;
    font-size: 14px;
    line-height: 24px;
    margin: 10px 0;
    font-weight: 300;
    color: $color-dark;
  }
}

.links {
  display: inline-flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  flex-basis: 60%;
  padding-bottom: 30px;

  li {
    margin-right: 40px;
    margin-bottom: 16px;
    line-height: 1.2;

    &:last-of-type {
      margin-bottom: 16px;
    }
  }

  a {
    @include hover {
      border-bottom: 2px solid $color-white;
    }
  }

  @include mq(tablet, max) {
    flex-direction: column;
  }
}

.linksPart {
}

.social {
  display: flex;
  flex-basis: none;
  margin-bottom: 20px;

  li {
    margin-right: 24px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  a {
    display: block;
  }

  svg {
    display: block;
    width: 25px;
    height: 30px;
  }
}

.privacy {
  text-decoration: underline;
  font-size: 16px;
  font-weight: 500;
}

@include mq(tablet) {
  .footerContainer {
    //padding: 0 50px 0;
    //max-width: 1500px;

    > div {
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .logo {
    padding-bottom: 0;
    margin-bottom: 0;
    align-items: flex-end;
    text-align: right;
  }
  .links {
    padding-bottom: 0;
    flex-direction: row;
    font-size: $fs-base;

    ul {
      li {
        margin-bottom: 16px;
        line-height: 1.2;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    a {
      @include hover {
        border-bottom: 2px solid $color-white;
      }
    }
  }
  .social {
    flex-basis: 150px;
    margin-bottom: 20px;

    svg {
      width: 21px;
      height: 27px;
    }
  }
  .privacy {
    font-size: 12px;
  }
}

@include mq(desktop-small) {
  .social {
    flex-basis: 200px;
  }
}
