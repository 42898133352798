@import '../setup/setup';

.nav {
  background: $color-gray;
  color: #5d6376;
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  &.open {
    z-index: 250;
  }
}

.navContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  background: $color-gray;
  z-index: 10;
}

.logo {
  display: block;
  width: 193px;
  transition: all 0.3s ease;

  svg {
    display: block;
    max-width: 100%;
    height: auto;
  }
}

.dropdown {
  position: absolute;
  bottom: -10px;
  right: 0;
  opacity: 0;
  transition: 0.2s all ease;
  transform: translateY(100%);
  display: flex;
  flex-direction: column;
  cursor: default;
  background-color: $color-white;
  box-shadow: 1px 5px 20px 5px rgba(163, 177, 198, 0.66);
  z-index: 1;
  pointer-events: none;

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  li {
    cursor: pointer;
    padding: 15px 25px;
    display: flex;
    align-items: center;
    line-height: 20px;
    white-space: nowrap;

    svg {
      margin-right: 10px;
      width: 20px;
      height: 100%;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    @include hover {
      background-color: $color-gray;
      text-decoration: none;
    }
  }

  &:after {
    content: '';
    position: absolute;
    right: 13px;
    top: -10px;
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 11px solid $color-white;
  }
}

.premium {
  bottom: -20px;
}

.profile {
  display: none;
  width: 50px;
  height: 50px;
  position: relative;
  cursor: pointer;
  z-index: 2;
  border-radius: 50%;
}

.profilePlaceholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: $color-blue;

  span {
    font-size: 20px;
    color: $color-white;
    font-weight: 700;
  }
}

.imgTag {
  position: absolute;
  background-color: $color-gold;
  bottom: -8px;
  color: $color-white;
  width: 100%;
  font-size: 8px;
  text-transform: uppercase;
  border-radius: 25px;
  padding: 5px;
  font-weight: 700;
  display: flex;
  align-items: center;
  line-height: 10px;
  justify-content: center;
  min-width: 60px;
  left: -5px;
  padding-top: 3px;
  padding-bottom: 3px;

  svg {
    width: 8px;
    height: 8px;
    margin-right: 2px;
  }
}

.navUser {
  flex: 1;
  display: flex;
  align-items: center;
}

.search {
  display: none;
}

.searchIcon {
  //position: absolute;
  display: flex;
  align-items: center;
  flex-basis: 18px;
  border-bottom: 1px solid #e4e4e4;

  svg {
    width: 18px;
    height: 18px;
  }
}

.searchInput {
  position: relative;
  flex: 1;
}

.searchInputElem {
}

.searchResults {
  position: absolute;
  top: 30px;
  right: 0;
  width: calc(100vw - 92px);
  background: $color-white;
  color: $color-black;
  max-height: calc(100vh - 70px);
  overflow: auto;
  box-shadow: $shadow-item;
}

.links {
  flex-grow: 1;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 20px;

  li {
    margin-right: 34px;
    white-space: nowrap;

    a {
      padding: 0 0 5px;
      border-bottom: 2px solid transparent;

      @include hover {
        border-bottom-color: $color-white;
      }
    }
  }
}

.cta {
  flex: 0 0 50px;
  display: none;
  justify-content: flex-end;
  width: 100%;
  height: 50px;
}

.btn {
  display: inline-flex;
  height: 56px;
  align-items: center;
  justify-content: center;
  background: none;
  font-size: $fs-base;
  background: $color-blue;
  color: $color-white;
  padding: 0 30px;
  transition: all 0.2s ease;
  white-space: nowrap;
  border-radius: 28px;

  @include hover {
    background: darken($color-blue, 10);
  }
}

.btnIcon {
  padding: 0 14px;
}

.btnPrimary {
}

.btnSecondary {
}

.mobileWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(-100%);
  transition: all 0.3s ease-in-out;
}

.mobileInnerContainer {
  background: $color-gray;
  width: 100%;
  padding-top: 112px; // nav height + 32
  padding-bottom: 32px;
}

.mobileWrapperActive {
  transform: none;
}

.mobileTrigger {
  position: relative;
  width: 22px;
  height: 18px;
  cursor: pointer;
}

.mobileTriggerLine {
  position: absolute;
  width: 22px;
  height: 2px;
  background: $color-black;
  top: 50%;
  left: 50%;
  transition: all 0.2s ease;
}

.mobileTriggerLine1 {
  transform: translate(-50%, -50%) translateY(-8px);
}

.mobileTriggerLine2 {
  transform: translate(-50%, -50%);
}

.mobileTriggerLine3 {
  transform: translate(-50%, -50%) translateY(8px);
}

.mobileTriggerActive {
  .mobileTriggerLine1 {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  .mobileTriggerLine2 {
    opacity: 0;
  }

  .mobileTriggerLine3 {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.mobileClose {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-top: 23px;
  margin-left: -27px;
  transform: translateY(-100px);
  transition: all 0.5s ease 0.3s;
  z-index: -1;

  svg {
    width: 55px;
  }
}

.mobileWrapperActive .mobileClose {
  transform: none;
}

.mobileSearch {
  position: relative;
  margin: 0 30px 0 auto;
}

.mobileSearchInput {
  position: absolute;
  right: 0;
  top: 50%;
  height: auto;
  width: 0;
  margin-top: -20px;
  padding: 11px;
  font-size: 16px;
  pointer-events: none;
  opacity: 0;
  transition: all 0.3s ease;
}

.mobileSearchButton {
  display: block;
  padding: 0;

  svg {
    display: block;
    width: 20px;
  }
}

.nav.mobileSearchActive {
  z-index: 250;

  .logo {
    transform: translateY(-60px);
  }

  .mobileSearch {
    //border: 3px solid red;
  }

  .mobileSearchInput {
    width: calc(100vw - 92px);
    opacity: 1;
    pointer-events: all;
  }
}

@include mq(tablet) {
  .navContainer {
    //display: flex;
  }
}

@include mq(desktop-small) {
  .navContainer {
    //display: flex;
  }

  .links {
    li {
      margin-right: 84px;
    }
  }

  .profile,
  .cta {
    display: flex;
  }

  .mobileTrigger,
  .mobileSearch {
    display: none;
  }

  .search {
    position: relative;
    flex-grow: 1;
    display: flex;
    padding: 0 38px 0 50px;
    align-items: stretch;
  }

  .searchResults {
    top: 100%;
    left: -18px; // icon
    right: auto;
    width: calc(100% + 18px);
  }
}

.ribbon {
  width: 20px;
  height: 28px;
  color: $color-white;
  font-size: 0;
  z-index: 2;
  border: 10px solid gray;
  border-bottom-color: #fff;
  position: absolute;
  top: -10px;
  z-index: 0;

  @media (max-width: 1024px) {
    top: -13px;
    left: 7px;
  }
}

.upgrade-to-premium-item {
  position: relative;
  width: 20px;

  @media (max-width: 1024px) {
    margin-right: 35px;
  }

  svg {
    position: absolute;
    top: -7px;
    right: 4px;
    width: 12px !important;
    height: 12px !important;
    z-index: 1;

    path {
      fill: white !important;
    }
  }
}
