@import '../setup/setup';

.avatar {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;

  > div {
    border-radius: 50%;
    overflow: hidden;
  }
}

.avatarLegend {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;

  > div {
    border-radius: 50%;
    overflow: hidden;
  }

  &::after {
    content: '';
    width: 2px;
    border: 2px solid #23d6f7;
    display: flex;
    border-radius: 100%;
    position: absolute;
    left: -4px;
    padding: 26px;
    top: -4px;
    z-index: -5;
  }
}

.profilePlaceholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: $color-blue;

  span {
    font-size: 20px;
    color: $color-white;
    font-weight: 700;
  }
}

.imgTag {
  position: absolute;
  background-color: $color-gold;
  bottom: -8px;
  color: $color-white;
  width: fit-content;
  left: 50%;
  transform: translateX(-50%);
  font-size: 8px;
  text-transform: uppercase;
  border-radius: 25px;
  padding: 2px 5px;
  font-weight: 700;
  display: flex;
  align-items: center;
  line-height: 10px;
  justify-content: center;

  svg {
    width: 8px;
    height: 8px;
    margin-right: 2px;
  }
}

.platinumImgTag {
  background: linear-gradient(90deg, #d7d7d7 0%, #9e9e9e 114%);
}

.imgTagLegend {
  position: absolute;
  background: linear-gradient(
    122.18deg,
    #0041c4 -99.94%,
    #0046c7 7.25%,
    #23d6f7 104.71%
  );
  bottom: -8px;
  color: $color-white;
  width: 100%;
  font-size: 8px;
  text-transform: uppercase;
  border-radius: 25px;
  padding: 5px;
  font-weight: 700;
  display: flex;
  align-items: center;
  line-height: 10px;
  justify-content: center;
  min-width: 60px;
  left: -5px;
  padding-top: 3px;
  padding-bottom: 3px;

  svg {
    width: 8px;
    height: 8px;
    margin-right: 2px;
  }
}
