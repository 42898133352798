@import '../setup/setup';

.item,
.itemNoResults {
  width: calc(100% - 20px);
  display: block;
  padding: 10px;
  align-items: center;

  background: $color-gray;
  margin: 10px;
  transition: all 0.1s ease;
}

.item:hover {
  //background: $color-blue;
  //background: $color-gray-darker;
  background: darken($color-gray, 10);

  .itemTitle,
  .itemAddress {
    //color: $color-white;
  }
}

.itemRow {
  display: flex;
  align-items: center;
}

.itemLogo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 20px;
  background-color: $color-white;
  flex-shrink: 0;
  padding: 10px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.itemTitle {
  font-size: 18px;
  font-weight: 900;
  margin-bottom: 5px;
}

.itemAddress {
  font-size: 14px;
}

.itemTags {
  display: flex;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
  margin-top: 10px;
  overflow-x: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.itemTag,
.itemPlus {
  white-space: nowrap;
  font-weight: 600;
  background: #e8e8e8;
  padding: 14px;
  text-align: center;
  //flex-basis: 25%;
  //max-width: 25%;

  &:not(:last-of-type) {
    margin-right: 4px;
  }
}

.itemTag {
  flex: 1;
}

@include mq(tablet) {
  .itemLogo {
    width: 80px;
    height: 80px;
    background-size: 60%;
  }
}
